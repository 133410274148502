<template>
  <div class="DefaultB2BConventionHonoraires">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CToaster :autohide="3000">
      <template v-for="toast in savedConventionToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
        >
        <template #default>
          <strong class="text-success">Convention sauvegardée 👍</strong>
        </template>
        </CToast>
      </template>
    </CToaster>

    <CRow>
      <CCol>
        <h1> Editez la convention type d'honoraires B2B de votre cabinet  </h1>
      </CCol>
    </CRow>

    <CRow>
      <CCol md="8">
        <p>
          Vous pouvez éditer directement en ligne chaque partie de la convention type d'honoraires B2B de votre cabinet.
        </p>
        <p>
          <strong>Cette convention sera celle
          utilisée par défaut pour toutes les missions dont le client est une entreprise.</strong>
        </p>
        <p style="font-size: 1.1em;">
          <strong class="text-danger"><CIcon name="cil-warning" />
            Attention</strong><br>
            <strong>[[CHAMP]] : se met à jour automatiquement.</strong> Si vous les modifiez, ces variables ne pourront plus se
            mettre à jour automatiquement.<br>
            <strong><span style="background-color: rgb(255,255,0)">XXX</span>: à modifier par vous-même pour construire votre convention type</strong>. Ces champs
            seront alors automatiquement repris dans toutes les conventions générées.
         </p>
      </CCol>
      <CCol md="4">
        <p>
          <strong><u>Légende</u></strong>
        </p>
        <p>
          <CIcon class="text-primary" size="lg" name="cil-save"/> : Enregistrer l'article<br>
          <CIcon class="text-dark" size="lg" name="cil-arrow-circle-top"/> : Décaler l'article d'un cran vers le haut<br>
          <CIcon class="text-dark" size="lg" name="cil-arrow-circle-bottom"/> : Décaler l'article d'un cran vers le bas<br>
          <CIcon class="text-danger" size="lg" name="cil-trash"/> : Supprimer l'article
        </p>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol class="text-right">
        <CButton color="outline-primary" @click="downloadDefaultConvention" shape="pill"><CIcon name="cil-cloud-download" /> Télécharger votre modèle de convention en pdf</CButton>
      </CCol>
    </CRow>

    <div class="mt-2">
      <CRow>
        <CCol>
          <h2 class="convention-title">Introduction</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyDefaultConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="defaultB2BConvention.introduction" :editor-toolbar="customToolbar" @blur="modifyDefaultConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <div class="mt-4">
      <CRow>
        <CCol>
          <h2 class="convention-title">Préambule</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyDefaultConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="defaultB2BConvention.preambule" :editor-toolbar="customToolbar" @blur="modifyDefaultConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;"> Articles de votre convention </h2>
      </CCol>
    </CRow>

    <div v-for="article in defaultB2BConvention.articles" :key="article.id" class="mt-4">
      <CRow>
        <CCol>
          <CInput
            size="lg"
            autocomplete="eclerk-no-autocomplete-input"
            v-model="article.title" type="text"
            maxlength="200"
            placeholder="Titre de votre article"
            invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
            @blur="modifyDefaultConventionHonorairesArticle(article)">

            <template #prepend-content>Article {{article.order}}</template>
          </CInput>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyDefaultConventionHonorairesArticle(article)">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
          <CButton
            v-if="article.order != 1"
            v-c-tooltip="'Monter'"
            square
            :disabled="isMovingB2BConventionItem"
            @click="ascendDefaultConventionHonorairesArticle(article)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-top"/>
          </CButton>
          <CButton
            v-if="article.order != defaultB2BConvention.articles.length"
            class="ml-1"
            v-c-tooltip="'Descendre'"
            square
            :disabled="isMovingB2BConventionItem"
            @click="descendDefaultConventionHonorairesArticle(article)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-bottom"/>
          </CButton>
          <CButton
            class="ml-1"
            v-c-tooltip="'Supprimer'"
            square
            :disabled="isDeletingB2BConventionItem"
            @click="deleteDefaultConventionHonorairesArticle(article)">
            <CIcon class="text-danger" size="lg" name="cil-trash"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="article.body" :editor-toolbar="customToolbar" @blur="modifyDefaultConventionHonorairesArticle(article)"/>
        </CCol>
      </CRow>
    </div>

    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;" class="text-success"> Ajouter un nouvel article </h2>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol md="6">
        <CInput
          size="lg"
          autocomplete="eclerk-no-autocomplete-input"
          v-model="newArticleTitle" type="text"
          maxlength="200"
          placeholder="Titre de votre nouvel article"
          @input="$v.newArticleTitle.$touch()"
          :isValid="$v.newArticleTitle.$dirty ? !$v.newArticleTitle.$error : null"
          invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
        >
        </CInput>
      </CCol>
      <CCol md="6">
        <CButton
          @click="createDefaultConventionHonorairesArticle"
          shape="pill"
          block
          size="lg"
          color="outline-success"
          :disabled="$v.newArticleTitle.$invalid || isCreatingB2BConventionItem">
            <CIcon name="cil-plus"/> Ajouter un article
        </CButton>
      </CCol>
    </CRow>

    <hr>


    <div class="mt-4">
      <CRow>
        <CCol>
          <h2 class="convention-title">Signature</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyDefaultConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="defaultB2BConvention.signature" :editor-toolbar="customToolbar" @blur="modifyDefaultConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;"> Annexes de votre convention </h2>
      </CCol>
    </CRow>


    <div v-for="annexe in defaultB2BConvention.annexes" :key="annexe.id" class="mt-4">
      <CRow>
        <CCol>
          <CInput
            size="lg"
            autocomplete="eclerk-no-autocomplete-input"
            v-model="annexe.title" type="text"
            maxlength="200"
            placeholder="Titre de votre article"
            invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
            @blur="modifyDefaultConventionHonorairesAnnexe(annexe)">

            <template #prepend-content>Annexe {{annexe.order}}</template>
          </CInput>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyDefaultConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
          <CButton
            v-if="annexe.order != 1"
            v-c-tooltip="'Monter'"
            square
            @click="ascendDefaultConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-top"/>
          </CButton>
          <CButton
            v-if="annexe.order != defaultB2BConvention.annexes.length"
            class="ml-1"
            v-c-tooltip="'Descendre'"
            square
            @click="descendDefaultConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-dark" size="lg" name="cil-arrow-circle-bottom"/>
          </CButton>
          <CButton
            class="ml-1"
            v-c-tooltip="'Supprimer'"
            square
            :disabled="isDeletingB2BConventionItem"
            @click="deleteDefaultConventionHonorairesAnnexe(annexe)">
            <CIcon class="text-danger" size="lg" name="cil-trash"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="annexe.body" :editor-toolbar="customToolbar" @blur="modifyDefaultConventionHonorairesAnnexe(annexe)"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <h2 style="font-size: 1.6rem !important; text-decoration: underline;" class="text-success"> Ajouter une nouvelle annexe </h2>
      </CCol>
    </CRow>

    <CRow class="mt-4">
      <CCol md="6">
        <CInput
          size="lg"
          autocomplete="eclerk-no-autocomplete-input"
          v-model="newAnnexeTitle" type="text"
          maxlength="200"
          placeholder="Titre de votre nouvelle annexe"
          @input="$v.newAnnexeTitle.$touch()"
          :isValid="$v.newAnnexeTitle.$dirty ? !$v.newAnnexeTitle.$error : null"
          invalid-feedback="Votre titre doit contenir entre 2 et 200 caractères"
        >
        </CInput>
      </CCol>
      <CCol md="6">
        <CButton
          @click="createDefaultConventionHonorairesAnnexe"
          shape="pill"
          block
          size="lg"
          color="outline-success"
          :disabled="$v.newAnnexeTitle.$invalid || isCreatingB2BConventionItem">
            <CIcon name="cil-plus"/> Ajouter une annexe
        </CButton>
      </CCol>
    </CRow>


    <div class="mt-4">
      <CRow>
        <CCol>
          <h2 class="convention-title">Bas de page - Sur chaque page</h2>
        </CCol>
        <CCol class="text-right align-self-center">
          <CButton
            v-c-tooltip="'Enregistrer'"
            square
            @click="modifyDefaultConventionHonoraires">
            <CIcon class="text-primary" size="lg" name="cil-save"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <vue-editor v-model="defaultB2BConvention.footer" :editor-toolbar="customToolbar" @blur="modifyDefaultConventionHonoraires"/>
        </CCol>
      </CRow>
    </div>


    <CRow class="mt-4">
      <CCol>
        <CButton block color="outline-primary" @click="downloadDefaultConvention" shape="pill"><CIcon name="cil-cloud-download" /> Télécharger votre modèle de convention en pdf</CButton>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'


const apiBossConnected = new APIBossConnected()

export default {
  name: 'DefaultB2BConventionHonoraires',
  components: {
    VueEditor,
    Loading
  },
  mixins: [
    validationMixin,
  ],
  data: function() {
    const today = new Date()
    return {
      today: today,

      isDefaultB2BConventionLoading: false,
      isDeletingB2BConventionItem: false,
      isMovingB2BConventionItem: false,
      isCreatingB2BConventionItem: false,

      preventNewSaving: false,
      savedConventionToasts: 0,

      defaultB2BConvention: {
        introduction: '',
        preambule: '',
        signature: '',
        footer: '',
        articles: [],
        annexes: []
      },

      newArticleTitle: '',
      newAnnexeTitle: '',

      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 2, 3, 4] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],

        ["blockquote" ],

        [{ list: "ordered" }, { list: "bullet" }],

        [{ script: "sub" }, { script: "super" }],

        [{ indent: "-1" }, { indent: "+1" }],

        [{ color: [] }, { background: [] }],

        ["clean"]

      ]
    }
  },

  validations: {
    newArticleTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
    newAnnexeTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
  },


  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isDefaultB2BConventionLoading || this.isDeletingB2BConventionItem || this.isMovingB2BConventionItem || this.isCreatingB2BConventionItem) {
        return true
      }
      return false
    },
  },
  created () {
   this.getDefaultConventionHonoraires()
  },


  methods: {

    getDefaultConventionHonoraires () {
      this.isDefaultB2BConventionLoading = true
      apiBossConnected.getDefaultConventionHonoraires(this.token, 'B2B')
      .then((result) => {
        this.defaultB2BConvention = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDefaultB2BConventionLoading = false
      })
    },

    modifyDefaultConventionHonoraires () {
      if (this.preventNewSaving) {
        return false
      }
      this.preventNewSaving = true
      apiBossConnected.modifyDefaultConventionHonoraires(
        this.token, this.defaultB2BConvention
      )
      .then(() =>{
        this.savedConventionToasts ++
        setTimeout(() => this.preventNewSaving = false, 5000);
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    downloadDefaultConvention () {
      apiBossConnected.downloadDefaultConventionHonorairesAsPdf(this.token, this.defaultB2BConvention.id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const conventionName = 'Modèle - Convention B2B.pdf'
          link.setAttribute('download', conventionName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },


    // ----- Default convention articles -----------

    createDefaultConventionHonorairesArticle () {
      this.isCreatingB2BConventionItem = true
      apiBossConnected.createDefaultConventionHonorairesArticle(
        this.token, this.defaultB2BConvention, this.newArticleTitle
      )
      .then((result) =>{
        this.defaultB2BConvention.articles.push(result.data)
        this.newArticleTitle = ''
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingB2BConventionItem = false
      })
    },

    modifyDefaultConventionHonorairesArticle (article) {
      if (article.title == '') {
        return false
      }
      else {
        if (this.preventNewSaving) {
          return false
        }
        this.preventNewSaving = true
        apiBossConnected.modifyDefaultConventionHonorairesArticle(
          this.token, article
        )
        .then(() =>{
          this.savedConventionToasts ++
          setTimeout(() => this.preventNewSaving = false, 5000);
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
      }
    },

    deleteDefaultConventionHonorairesArticle (article) {
      this.isDeletingB2BConventionItem = true
      apiBossConnected.deleteDefaultConventionHonorairesArticle(
        this.token, article
      )
      .then(() =>{
        this.getDefaultConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDeletingB2BConventionItem = false
      })
    },

    ascendDefaultConventionHonorairesArticle (article) {
      this.isMovingB2BConventionItem = true
      apiBossConnected.ascendDefaultConventionHonorairesArticle(
        this.token, article.id
      )
      .then(() =>{
        this.getDefaultConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingB2BConventionItem = false
      })
    },

    descendDefaultConventionHonorairesArticle (article) {
      this.isMovingB2BConventionItem = true
      apiBossConnected.descendDefaultConventionHonorairesArticle(
        this.token, article.id
      )
      .then(() =>{
        this.getDefaultConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingB2BConventionItem = false
      })
    },



    // ----- Default convention annexes -----------

    createDefaultConventionHonorairesAnnexe () {
      this.isCreatingB2BConventionItem = true
      apiBossConnected.createDefaultConventionHonorairesAnnexe(
        this.token, this.defaultB2BConvention, this.newAnnexeTitle
      )
      .then((result) =>{
        this.defaultB2BConvention.annexes.push(result.data)
        this.newAnnexeTitle = ''
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingB2BConventionItem = false
      })
    },

    modifyDefaultConventionHonorairesAnnexe (annexe) {
      if (annexe.title == '') {
        return false
      }
       else {
         if (this.preventNewSaving) {
           return false
         }
         this.preventNewSaving = true
         apiBossConnected.modifyDefaultConventionHonorairesAnnexe(
           this.token, annexe
         )
         .then(() =>{
           this.savedConventionToasts ++
           setTimeout(() => this.preventNewSaving = false, 5000);
         })
         .catch(() => {
           this.$store.commit('openGlobalErrorModal')
         })
       }
    },

    deleteDefaultConventionHonorairesAnnexe (annexe) {
      this.isDeletingB2BConventionItem = true
      apiBossConnected.deleteDefaultConventionHonorairesAnnexe(
        this.token, annexe
      )
      .then(() =>{
        this.getDefaultConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDeletingB2BConventionItem = false
      })
    },

    ascendDefaultConventionHonorairesAnnexe (annexe) {
      this.isMovingB2BConventionItem = true
      apiBossConnected.ascendDefaultConventionHonorairesAnnexe(
        this.token, annexe.id
      )
      .then(() =>{
        this.getDefaultConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingB2BConventionItem = false
      })
    },

    descendDefaultConventionHonorairesAnnexe (annexe) {
      this.isMovingB2BConventionItem = true
      apiBossConnected.descendDefaultConventionHonorairesAnnexe(
        this.token, annexe.id
      )
      .then(() =>{
        this.getDefaultConventionHonoraires()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMovingB2BConventionItem = false
      })
    },
  }
}

</script>
<style>
.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}
</style>
